/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description Q&A 常见问题
*/
<template>
  <section class="faq-section">
    <b-container>
      <b-row class="row align-items-center">
        <b-col lg="6">
          <div class="content-side">
            <div class="heading-title">
              <h6><span></span> Know more</h6>
              <!--      Frequently Ask Question        -->
              <h2>来看看一些常见问题</h2>
              <p></p>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-for="(item,index) in list" :key="index">
                <h2 class="accordion-header" id="headingOne">
                  <b-button class="accordion-button collapsed" @click="handleFqaItem(index,$event)">
                    {{ `${index+1}. ${item.question}` }}
                  </b-button>
                </h2>
                <div class="accordion-collapse collapse animated fadeIn" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>{{item.answer}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col lg="6">
          <div class="images-side">
            <img src="../../assets/img/faq/faq-side.png" alt="img">
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'QuestionAndAnswer',
  props: {
    qaList: {
      type: [],
      default: () => { return [] }
    }
  },
  data() {
    return {
      list: [{
        question: '注册试用需要收费吗？',
        answer: '不用的，您可以免费注册并试用店铺 7 天。提前体验后台功能和操' +
          '作，更好测试是否符合自己需求，在试用期内，Vllshop不会收取' +
          '您任何费用'
      },
      {
        question: 'Vllshop是否支持绑定自己的域名？',
        answer: '支持的。如果您已购买了自己的域名，您可以直接将购买的' +
            '域名绑定到Vllshop店铺。在网站管理后台店铺的“域名绑定”' +
            '模块即可完成域名绑定，不懂操作的Vllshop这边也会有顾问和' +
            '客服全程指导操作。'
      },
      {
        question: '需要购买服务器来运行Vllshop吗？',
        answer: '不需要的。Vllshop是一款SaaS工具，采用的是独立站云服务' +
            '器。您只需要在Vllshop注册账号开店，根据自身需求选择合适的' +
            '套餐即可，所有后续的系统维护服务器升级Vllshop帮你做。'
      },
      {
        question: '客户下单后我如何发货？',
        answer: '可以使用Vllshop提供的U闪达物流解决方案，也可以自定义添加' +
            '自己找的物流公司发货，发货和国内发货类似。'
      },
      {
        question: '客户下单后我如何收款？',
        answer: '有线上和线下2种收款方式，一般都是做线上的，看你的产品和做' +
            '的地区不一样，我们会推荐不同的收款方式，我们系统已经对接了' +
            '多种支付方式，只需要帮账号填在后台就能收款了。'
      },
      {
        question: '收到款我如何提款人民币？',
        answer: '例如用PayPal或其他第三方收款公司收款后，款是到第三方平台' +
            '的，可以通过结汇如注册个人/企业WF账号，在PayPal或第三方收' +
            '款账号里面绑定WF账号，再通过WF账号提款到自己国内银行卡就' +
            '就实现了结汇流程。'
      }]
    }
  },
  created() {
    if (this.qaList.length > 0) {
      this.list = this.qaList
    }
  },
  methods: {
    handleFqaItem(index, e) {
      console.log(index)
      if ($(e.target).hasClass('collapsed')) {
        $('.accordion .accordion-button').addClass('collapsed')
        $('.accordion .accordion-collapse').removeClass('show')
        $(e.target).removeClass('collapsed')
        $(`.accordion .accordion-collapse:eq(${index})`).addClass('show')
      } else {
        $(e.target).addClass('collapsed')
        $('.accordion .accordion-collapse').removeClass('show')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-title {
  max-width: 670px;
  margin: 0 auto;
  padding-bottom: 45px;
}
.heading-title h2 {
  font-size: 36px;
  font-weight: 600;
  color: #111111;
  line-height: 54px;
  text-align: center;
  padding-bottom: 30px;
}
.heading-title h6 {
  font-size: 14px;
  font-weight: 600;
  color: #111111;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heading-title h6 span {
  display: inline-block;
  margin-right: 10px;
  height: 3px;
  width: 50px;
  background: #FF9C1A;
}
.heading-title p {
  font-size: 16px;
  font-weight: 400;
  color: #313131;
  line-height: 28px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}
.faq-section {
  padding: 100px 0;
  background: url(../../assets/img/faq/faq-bg.png) no-repeat center center;
  background-size: contain;
}
.faq-section .content-side .heading-title {
  max-width: 420px;
  margin-left: 0;
}
.faq-section .content-side .heading-title h6 {
  justify-content: start;
}
.faq-section .content-side .heading-title h2 {
  text-align: left;
}
.faq-section .content-side .heading-title p {
  text-align: left;
}
.faq-section .content-side .accordion .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  background-color: transparent;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.accordion-button::after{
  margin-left: auto;
  width: 1rem;
  height: 1rem;
  transition: transform .2s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}
.faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button {
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  background: #523680;
  color: #fff;
  border: none;
}
.faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button::after {
  background: none;
  content: "+";
  color: #fff;
  font-size: 30px;
  line-height: 14px;
}
.faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button:active, .faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button:focus {
  box-shadow: none;
  outline: 0;
}
.faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button.collapsed {
  color: #313131;
  background: #F4F2F9;
}
.faq-section .content-side .accordion .accordion-item .accordion-header .accordion-button.collapsed::after {
  content: "-";
  color: #313131;
}
.faq-section .content-side .accordion .accordion-item .accordion-collapse {
  border: 1px solid #F4F2F9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.faq-section .content-side .accordion .accordion-item .accordion-body p {
  font-size: 16px;
  font-weight: 400;
  color: #313131;
  text-align: left;
}
.faq-section .images-side {
  text-align: right;
  margin-right: -150px;
}
.faq-section .images-side img {
  max-width: 100%;
}
@media (max-width: 1600px) {
  .faq-section .images-side {
    margin: 0;
  }
}
@media (max-width: 991px) {
  .faq-section .images-side {
    display: none;
  }
  .faq-section {
    padding: 60px 0;
  }
}
</style>
