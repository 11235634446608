/**
* @author  XuHongli
* @date  2022/2/17 13:58
* @version 1.0
* @description 价格表
*/
<template>
  <div class="pricing-section">
    <div class="pricing-section-wrapper">
      <b-container>
        <b-row>
          <b-col cols="10" lg="12" offset-lg="0" md="8" offset-md="2" offset="1">
            <div class="section-heading center c-white">
              <div class="sub-heading upper ls-1">
                <h5>Our app rates</h5>
              </div>
              <el-button  round type="primary" style="width: 200px;height: 50px;font-size: 2rem"  @click="$router.push({ name: 'Register',query: { registerType : 'login' } })"> 立刻免费试用 </el-button>

              <div class="main-heading">
                <!--                <h1>Pricing plans for you.</h1>-->
                <h1 style="letter-spacing: 2px;margin-bottom: 2.1rem">
                  {{ $t('PricingSection.headerTitle') }}
                </h1>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="pricing">
          <b-row />
          <b-row>
            <b-col>
              <StorePackageTable class="show-table" />
              <StorePackageMobileTable class="show-mtable" />
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="background-pattern background-pattern-1">
        <div class="background-pattern-img background-loop" :style="[{backgroundImage: 'url('+ require('../../assets/img/pricing/pattern.jpg') +')'}]" />
        <div class="background-pattern-gradient" />
        <div class="background-pattern-bottom">
          <div class="image" :style="[{backgroundImage: 'url('+ require('../../assets/img/pricing/pattern-1.jpg') +')'}]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StorePackageMobileTable from '@/components/StorePackageTable/mobiletable'
import StorePackageTable from '@/components/StorePackageTable'
import Swiper from '@/docs/swiper-bundle.min'

export default {
  components: { StorePackageTable, StorePackageMobileTable },
  props: {
    dataList: {
      type: Array,
      default: () => {
        return [{
          title: '爱心版',
          price: '0',
          priceYear: '空',
          context: '功能丰富、顾问式服务适合企业品牌出海',
          serviceContent: ['Vllshop专门打造了一版，', '提供服务于所有迷途者，', '(大学生创业、', '支持退伍军人创业政策、', '响应残联就业、创业)']
        },
        {
          title: '基础版',
          price: '78',
          priceYear: '750',
          context: '功能丰富、顾问式服务适合企业品牌出海',
          serviceContent: ['低成本体验建站，', '适合新手卖家快速上手']
        },
        {
          title: '专业版',
          price: '92',
          priceYear: '940',
          context: '功能丰富、顾问式服务适合企业品牌出海',
          serviceContent: ['多种运营模式，', '让销量节节攀升', '助力卖家成为', '跨境电商职业选手']
        },
        {
          title: '旗舰定制版',
          price: '空',
          priceYear: '14120',
          context: '功能丰富、顾问式服务适合企业品牌出海',
          serviceContent: ['Vllshop给买家提供旗舰定', '制版独立站服务', '定制私人电商解决方案', '提供零到一 一站式服务', '独立站销售培训', '运营经验分享', '专属咨询顾问一对一服务']
        }]
      }
    }
  },
  data() {
    return {
      pricing_slider: '',
      priceHeight: '80px',
      priceWidth: '300px' // '135px'
    }
  },
  created() {
    this.$nextTick(() => {
      // this.priceHeight = Math.max(this.$refs.month.offsetHeight, this.$refs.year.offsetHeight) + 'px'
      // this.priceWidth = Math.max(this.$refs.month.offsetWidth, this.$refs.year.offsetWidth) + 'px'
    })
  },
  mounted() {
    this.pricing_slider = new Swiper('.pricing-slider .swiper-container', {
      resizeObserver: true,
      breakpoints: {
        0: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.2,
          spaceBetween: 30,
          initialSlide: 1
        },
        400: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.4,
          spaceBetween: 30,
          initialSlide: 1
        },
        576: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.5,
          spaceBetween: 30,
          initialSlide: 1
        },
        768: {
          enabled: true,
          centeredSlides: true,
          slidesPerView: 1.8,
          spaceBetween: 30,
          initialSlide: 1
        },
        992: {
          slidesPerView: 4,
          spaceBetween: 30,
          enabled: false,
          centeredSlides: false
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
// 显示
.show-mtable{
  display: unset;
}
.show-table{
  display: none;
}
.main-content{
  filter: drop-shadow(0px 20px 25px rgba(59, 45, 240, 0.2));
}
h1,h5,h4{
  color: #fff !important;
}
.container{
  max-width: 1300px;
}
.c-white {
  color: #fff;
  fill: #fff;
}
.ls-1 {
  letter-spacing: .1rem;
}
.upper {
  text-transform: uppercase;
}
h1 {
  font-size: 60px;
  font-weight: 600;
  line-height: 80px;
  letter-spacing: -2px;
}
h4 {
  font-size: 2.2rem;
}
// title
.pricing-section-wrapper{
  position: relative;
  padding: 150px 0 0 0;
  .background-pattern{
    width: 100%;
    height: 56vh; //////aah
  }
}
.pricing-section .section-heading {
  position: relative;
  z-index: 2;
}
.section-heading.center {
  text-align: center;
  margin: 0 auto;
}
.section-heading .sub-heading {
  display: flex;
  align-items: center;
  margin: 0 auto 1.8rem 0;
}
.section-heading.center .sub-heading {
  justify-content: center;
}
.section-heading .sub-heading h5 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: .3rem 0 0 0;
}
// background
.background-pattern{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 80%;
  height: 100%;
}
.background-pattern-img {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .15;
  z-index: 2;
  //mix-blend-mode: color-burn;
  mix-blend-mode: darken;
  background-position: 0 center;
}
.background-pattern-1 .background-pattern-gradient {
  filter: drop-shadow(0px 50px 50px rgba(94,215,254,.2));
}
.background-pattern-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(94,215,254,.2);
  background: linear-gradient(45deg, #3B2DF0 5%, rgba(153,157,253, 1) 50%, rgba(13,9,36,1) 100%);
  z-index: 1;
}
.background-pattern-bottom {
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: -150px;
  .image{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
//pricing
.pricing-section .pricing {
  position: relative;
  z-index: 2;
}
.pricing .swiper-container {
  overflow: visible;
}
.pricing-single {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5.8rem 4rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}
.pricing-single.basic {
  filter: drop-shadow(0px 20px 25px rgba(59,45,240,.2));
}
.pricing-single .plan {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.pricing-single.basic .plan {
  color: #3B2DF0 !important;
}
.pricing-single .price {
  font-size: 30px;
  font-weight: 500;
  color: #6a6f77;
  text-align: center;
  margin: 22px 0 60px 0;
  overflow: hidden;
  position: relative;
}
//month
.pricing-single .price .month {
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translateX(-50%);
  transition: all .25s ease-out 0s;
}
.pricing-single .price-month .month {
  top: 0;
}
//year
.pricing-single .price .year {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all .25s ease-out 0s;
}
.pricing-single .price-month .year {
  top: 50%;
}
//sup
.pricing-single .price sup {
  font-size: 1.4rem;
}
sup {
  top: -.5em;
}
sub, sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline;
}
// a btn
.button {
  width: max-content;
  background-color: #000;
  border: none;
  overflow: hidden;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
}
a.button{
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
}
.pricing-single .button {
  width: 100%;
}
.button-basic {
  color: #fff;
  background: rgba(255,147,242, 1);
  background: linear-gradient(90deg, #3B2DF0 0%, rgba(255,147,242, 1) 100%);
  filter: drop-shadow(0px 10px 10px rgba(255,147,242,.4));
}
a:hover {
  color: #0a58ca;
}
.button-inner {
  display: flex;
  align-items: center;
  padding: 1.9rem 5.6rem;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  border-bottom-left-radius: 4rem;
}
.button-basic .button-inner::after {
  background-color: rgb(255, 147, 242);
}
.button:hover .button-content {
  color: #fff;
}
.pricing-single .button-content {
  margin: 0 auto;
}
.button-content {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pricing-single .button-content {
  margin: 0 auto;
}
//table
.pricing-single ul {
  list-style: none;
  margin: 6rem 0 7.5rem 0;
}
ul {
  padding-left: 0;
}
.pricing-single ul li {
  font-size: 1.6rem;
  font-weight: 500;
  color: #6a6f77;
  text-align: left;
}
.pricing-single ul li::before{
  content: '';
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(59, 45, 240, 0.75);
}
ul li {
  line-height: 3rem;
}
.pricing-single h6 {
  font-size: 1.2rem;
  text-align: center;
  color: #6a6f77;
}
</style>
<style lang="scss" scoped>
  @media only screen and (min-width:1199px){
    .show-mtable{
      display: none;
    }
    .show-table{
      display: unset;
    }
  }
</style>
