 <template>
  <div class="business">
    <PricingSection style="overflow-x: hidden;" />
    <p class="title" style="margin-top:225px;margin-bottom: 0px">
      如何选购合适的版本
    </p>
    <div
      style="
      display: flex;
      flex-direction: column;
      align-items: center;"
    >
      <!--      <StorePackageTable />-->
    </div>
    <TestimonialSection />
    <p class="title" style="margin-bottom: 0px">
      常见问题
    </p>
    <QuestionAndAnswer :qa-list="queAList" />
    <price-footer-banner></price-footer-banner>
  </div>
</template>

<script>
import TestimonialSection from 'components/TestimonialSection'
import PricingSection from 'components/Price'
import QuestionAndAnswer from 'components/QuestionAndAnswer'
import { qaList } from './qaList'
import PriceFooterBanner from '@/layout/footer/priceFooterBanner'

export default {
  name: 'Business',
  components: { PriceFooterBanner, QuestionAndAnswer, TestimonialSection, PricingSection },
  data() {
    return {
      queAList: [],
      hoverPriceFlag: 0,
      hoverPriceStatus: false,
      tipsList: [{
        q: '我们公司是做流行饰品的，销售地区主要在英国和美国，之前都是通过参加国外的时尚展会和T台走秀以及业务员开发客户的模式进行销售的。但是这段时间因为各种原因不能到国外进行线下的客户拓展，同时越来越多的个体和新客户需要线上看产品，所以我们打算搭建一个独立站。',
        a: '根据您的详细描述我了解到贵公司的情况是这样的：线下的主要销售地区使用的语言场景是以英文为主，搭建网站的主要目的是方便客户在线查看产品，二是通过网站获取询盘，以弥补不能参加线下的产品展示活动所减少的潜在客户。那么刚开始我建议选择基础版就能满足建站需求了。',
        qName: 'Alex',
        qAvatar: require('assets/img/avatar/Alex.png'),
        aAvatar: require('assets/img/avatar/1.png')
      }, {
        q: '我们公司是做服装外贸的，已经做了8年了。主要做东亚地区，业务流量稳定，但是的平台抽佣过高，以及运输状态不稳定所以打算尝试独立建站，并',
        a: '贵公司的情况我了解了。独立站的确能通过谷歌搜索引擎或者Facebook等社交媒体展示到不同的地区。这时，你的网站就需要有不同的语种，因为谷歌搜索引擎是针对不同的地区，搜索不同语种的网站。如果你想通过独立站去了解哪个市场可以尝试开拓，我建议选择我们的多语种版本，可以自动翻译多种语言，这样你根据哪个地区的询盘比较多、转化比较好，再去判断是否深耕这个地区的客户。',
        qName: 'Christina',
        qAvatar: require('assets/img/avatar/Christina.png'),
        aAvatar: require('assets/img/avatar/2.png')
      }, {
        q: '我们公司是做流行饰品的，销售地区主要在英国和美国，之前都是通过参加国外的时尚展会和T台走秀以及业务员开发客户的模式进行销售的。但是这段时间因为各种原因不能到国外进行线下的客户拓展，同时越来越多的个体和新客户需要线上看产品，所以我们打算搭建一个独立站。',
        a: '根据您的详细描述我了解到贵公司的情况是这样的：线下的主要销售地区使用的语言场景是以英文为主，搭建网站的主要目的是方便客户在线查看产品，二是通过网站获取询盘，以弥补不能参加线下的产品展示活动所减少的潜在客户。那么刚开始我建议选择基础版就能满足建站需求了。',
        qName: 'Adam',
        qAvatar: require('assets/img/avatar/Adam.png'),
        aAvatar: require('assets/img/avatar/3.png')
      }]
    }
  },
  created() {
    this.queAList = qaList
  },
  mounted() {

  },
  methods: {
    hoverPrice(index) {
      console.log(index)
      this.hoverPriceFlag = index
      this.hoverPriceStatus = true
    },
    leavePrice() {
      this.hoverPriceStatus = false
    }
  }
}
</script>

<style lang="scss" scoped>
.business{
}
.title{
  font-size: $titleSize;
}
.nav{
  margin-top: 30px;
  margin-bottom: 50px;
}
.bgcWhite{
  background-color: #fff;
}
.hover-price-item{
  position: absolute;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  top: 0;
  left: -15px;
  width: 452px;
  height: 754px;
  z-index: 10;
  padding: 35px 0 53px;
  border-radius: 5px;
  border: 2px solid #378CFF;
  .title{
    font-size: 39px;
  }
  .price{
    font-size: 72px;
    color: #141414;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .line{
    height: 2px;
    width: 312px;
    background-color: #4957D4;
    margin-bottom: 38px;
  }
  .serviceContent-item{
    display: flex;
    justify-content: flex-start;
    width: 312px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    &:before{
      width: 12px;
      border-radius: 50%;
      height: 12px;
      background-color: #6B77DC;
      display: block;
      content:'';
      margin-right: 27px;
    }
  }
}
.price-item:nth-of-type(3){
  .hover-price-item{
    left: 0;
  }
}
.banner_bottom{
  width: 1903px;
  height: 375px;
  cursor: pointer;
}
</style>
