<template>
  <div id="card" class="main-content">
    <headerTable :list="list" />

    <!-- <div
      class="main-content-card"
      :style="{ height: showText ? '466px' : '300px' }"
    >
      <div class="main-content-card-one">
        <div>
          <div class="content-txt" style="margin-bottom: 10px;margin-right: 999px;">
            VLLSHOP
          </div>
          <div class="content-txt">
            功能细则
          </div>
        </div>
      </div>
      <div style="display:flex;flex:1">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="main-content-card-other"
          :style="item.color"
          :class="index==3?'cla':''"
        >
          <div class="main-content-card-other-col" :class="index==3?'ccca':''">
            <div class="card-title">
              {{ item.varName }}
            </div>
            <div class="card-title">
              {{ item.enName }}
            </div>
            <div
              v-show="showText"
              style="margin-top: 64px;height: 100px;text-align: center; display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    font-size: 15px;"
            >
              <p>{{ item.contentone }}</p>
              <p>{{ item.contenttwo }}</p>
              <p>{{ item.contentthree }}</p>
              <p>{{ item.contentfour }}</p>
              <p>{{ item.contentfive }}</p>
              <p>{{ item.contentsix }}</p>
            </div>
            <div class="card-price-month" style="margin-top: 60px">
              {{ item.price == 0 ? "免费" : item.price }}
            </div>
            <div class="card-price-month" style="margin-bottom: 36px">
              {{ item.price2 == 0 ? "&nbsp;" : item.price2 }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="mytable">
      <!-- 介绍 -->
      <detail-table :columns="columns" :data="data" :show-header="false" />

      <!-- 店铺管理 -->
      <detail-table :columns="columns1" :data="data1" header-title="店铺管理" />

      <!-- 一件代发 -->
      <detail-table :columns="columns1" :data="data2" header-title="一件代发" />

      <!-- 广告投放 -->
      <detail-table :columns="columns1" :data="data3" header-title="广告投放" />

      <!-- 站内转化 -->
      <detail-table :columns="columns1" :data="data4" header-title="站内转化" />

      <!-- 特色功能 -->
      <detail-table :columns="columns1" :data="data5" header-title="特色功能" />

      <!-- 云手机 -->
      <detail-table :columns="columns1" :data="data33" header-title="云手机" />

      <!-- 贴心服务 -->
      <detail-table :columns="columns1" :data="data6" header-title="贴心服务" />
      <p class="tipText">
        *以上价格套餐内容自2021年8月23日起生效。
      </p>
    </div>
  </div>
</template>

<script>
// test
import headerTable from './headerTable.vue'

import detailTable from '@/components/StorePackageTable/detailTable.vue'
import { getPriceList } from '@/api/register'
import {
  columns,
  columns1,
  data,
  data1,
  data2,
  data33,
  data3,
  data4,
  data5,
  data6
} from '../../views/shop/StorePackage/StorePackageData'

export default {
  components: {
    detailTable,
    headerTable
  },
  data() {
    return {
      showText: true,
      data,
      data1,
      data2,
      data33,
      data3,
      data4,
      data5,
      data6,
      columns,
      columns1,
      list: [
        {
          color: 'background-color: #EE9CA7;',
          varName: '爱心版',
          enName: 'Love version',
          contentone: 'Vllshop响应国家号召',
          contenttwo: '针对应届大学生、',
          contentthree: '退伍军人、残疾人',
          contentfour: '等特殊群体的',
          contentfive: '就业和创业',
          contentsix: '提供爱心版免费服务',
          price: '0',
          price2: '0',
          butLabel: '申请套餐',
          type: 'V1'
        },
        {
          color: 'background-color: #4B6CB7;',
          varName: '基础版',
          enName: 'Basic Edition',
          contentone: '绝佳的启动方式',
          contenttwo: '适合新手卖家快速上手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '4300元/年',
          price2: '430元/月',
          butLabel: '购买套餐',
          type: 'V2'
        },
        {
          color: 'background-color: #19397B;',
          varName: '专业版',
          enName: 'Professional Edition',
          contentone: '轻松运营销量节节攀升',
          contenttwo: '晋级跨境电商职业选手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '8800元/年',
          price2: '880元/月',
          butLabel: '购买套餐',
          type: 'V3'
        },
        {
          color: 'background-color: #121A43;',
          varName: '旗舰定制版',
          enName: 'Fagship',
          contentone: 'Vllshop给买家提供旗舰定',
          contenttwo: '制版独立站服务',
          contentthree: '提供零到一一站式服务',
          contentfour: '独立站销售培训',
          contentfive: '运营经验分享',
          contentsix: '专属咨询顾问一对一服务',
          price: '89999元/年',
          price2: '0',
          butLabel: '购买套餐',
          type: 'V4'
        }
      ]
    }
  },
  mounted() {
    this.getPriceList()
  },
  methods: {
    // 获取套餐列表
    getPriceList() {
      getPriceList().then((res) => {
        if (res.code === 0) {
          const priceList = res.data.records
          this.list.map((item) => {
            priceList.map((resItem) => {
              if (item.type === resItem.code) {
                item.price = resItem.yearPrice === 0 ? resItem.yearPrice : `${resItem.yearPrice}元/年` // 年
                item.price2 = resItem.monthPrice === 0 ? resItem.monthPrice : `${resItem.monthPrice}元/月` // 年
                // 旗舰版额外处理
                if (item.type === 'V4') {
                  item.price2 = '0' // 旗舰版没有月付
                }
              }
            })
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .cla{
  flex: 1 !important;
  min-width: 215px !important;
}
.ccca{
  width: 100%;
  min-width: 0px !important;
}

// 提示文字
.tipText {
  padding-top: 8px;
  padding-bottom: 20px;
  color: #7a8499;
  font-size: 12px;
  font-family: PingFang SC;
  background-color: #f4f4f4;
}

// 左侧文字
.content-txt {
  color: #191a1c;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
}
// 卡片内容
.card-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.card-price {
  align-items: center;
  height: 56px;
  font-weight: 500;
  font-size: 36px;
  font-family: "Roboto";
}
.card-price-sub {
  display: inline-flex;
  height: 50px;
  margin-left: 4px;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  opacity: 0.8;
}
.card-price-month {
  //   margin-bottom: 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.main {
  width: 100%;
  max-width: 1920px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
  &-title {
    margin-top: 24px;
    width: 1263px;
    text-align: left !important;
  }
  &-subtitle {
    margin-top: 8px;
    display: flex;
    width: 1263px;
    align-items: flex-start;
    &-left {
      color: rgb(36, 40, 51);
      font-size: 30px;
      line-height: 42px;
      font-weight: bold;
    }
    &-right {
      margin-left: 40px;
      color: #356dff;
      border-color: #356dff;
    }
  }
  &-content {
    margin-top: 24px;
    width: 1263px;
    text-align: left;
    background: #fff;

    &-card {
      position: sticky;
      top: 91px;
    //   z-index: 1000 !important;
      background: rgb(170, 97, 97);
      border: solid 0.1px #e0e0e0;
      display: flex;
      transition: height .2s ease-in-out;
      &-one {
        // flex: 1;
        display: flex;
        align-items: center;
        padding-left: 40px;
        width: 318px;
        height: inherit;
        background: #fff;
        border-bottom: solid 0.1px #e0e0e0;
      }
      &-other {
        // flex: 1;
        min-width: 235px;
        background: #fff;
        color: #fff;
        height: inherit;
        display: flex;
        align-items: center;
        // background: linear-gradient(270deg, #75afe2 0%, #9dcdf3 100%);
        &-col {
          // flex: 1;
          // min-height: 368px;
          min-width: 235px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    &-mytable {
      overflow: hidden;
    }
  }
}
</style>
