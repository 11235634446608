export const qaList = [{
  question: '网站利于收录和推广吗？',
  answer: '可以，网站我们已经做好了整体的Google优化框架结构，以及做\n' +
      '好了Google GA和Facebook像素追踪，保证网站利于运营。'
},
{
  question: '网站支持样品下单吗？',
  answer: '支持，网站支持通过客户询盘产品转换生成样品订单'
}, {
  question: '网站效果怎样？',
  answer: '网站效果取决于推广策略所带来的流量，以及网站自身体验度与营\n' +
        '销互动功能搭配所形成高转化，Vllshop专注外贸网站开发细节，\n' +
        '符合国外浏览风格设计，以及网站实现每个页面都有营销转化点，\n' +
        '实现网站每个页面都有转化几率。'
},
{
  question: '你们网站打开速度快吗？',
  answer: '完全没问题的，我们采用海外一线品牌服务，加载全球CDN加速，\n' +
      '保障海外打开速度顺畅。'
},
{
  question: '网站支持询盘吗？',
  answer: '支持，网站不仅支持产品询盘，还支持多产品询盘，减少客户操作\n' +
      '次数，提升体验感。'
},
{
  question: '是否支持前后台统一管理呢？',
  answer: '支持，我们的后台有专门的订单、商品、采购等功能，可以帮助用\n' +
      '户实现闭环经营管理。'
}
]
