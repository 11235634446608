<template>
  <a-table
    class="table-head"
    :pagination="false"
    :show-header="true"
    :columns="columns"
    :data-source="[]"
    bordered
  >
    <!-- TODO 新的头部卡片 -->
    <span slot="customTitle" style="background-color: #fff">
      <div
        :style="{ height: showText ? '464px' : '300px' }"
        class="card-one"
        style="background-color: #fff"
      >
        <div>
          <div class="content-txt" style="margin-bottom: 10px">VllShop</div>
          <div class="content-txt">功能细则</div>
        </div>
      </div>
    </span>

    <!-- 卡片一 -->
    <span slot="customTitleOne">
      <div
        v-for="(item, index) in list.slice(0, 1)"
        :key="index"
        class="other"
        :style="item.color"
        :class="index == 3 ? 'cla' : ''"
      >
        <div class="other-col" :class="index == 3 ? 'ccca' : ''">
          <div class="card-title">
            {{ item.varName }}
          </div>
          <div class="card-title">
            {{ item.enName }}
          </div>
          <div
            v-show="showText"
            style="
              margin-top: 64px;
              height: 100px;
              text-align: center;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              font-size: 15px;
            "
          >
            <p>{{ item.contentone }}</p>
            <p>{{ item.contenttwo }}</p>
            <p>{{ item.contentthree }}</p>
            <p>{{ item.contentfour }}</p>
            <p>{{ item.contentfive }}</p>
            <p>{{ item.contentsix }}</p>
          </div>
          <div class="card-price-month" style="margin-top: 60px">
            <!-- ¥400/月 -->
            {{ item.price == 0 ? "免费" : item.price }}
          </div>
          <div class="card-price-month" style="margin-bottom: 36px">
            <!-- 月付 ¥480/月 -->
            {{ item.price2 == 0 ? "&nbsp;" : item.price2 }}
          </div>
          <!-- <el-button
            :class="comboId > item.comboId ? 'nobuybtn' : 'buybtn'"
            :disabled="comboId > item.comboId"
            @click="toPayPage(item)"
          >
            {{ comboId > item.comboId ? "你已购买更高版本" : item.butLabel }}
          </el-button> -->
        </div>
      </div>
    </span>

    <!-- 卡片二 -->
    <span slot="customTitleTwo">
      <div
        v-for="(item, index) in list.slice(1, 2)"
        :key="index"
        class="other"
        :style="item.color"
        :class="index == 3 ? 'cla' : ''"
      >
        <div class="other-col" :class="index == 3 ? 'ccca' : ''">
          <div class="card-title">
            {{ item.varName }}
          </div>
          <div class="card-title">
            {{ item.enName }}
          </div>
          <div
            v-show="showText"
            style="
              margin-top: 64px;
              height: 100px;
              text-align: center;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              font-size: 15px;
            "
          >
            <p>{{ item.contentone }}</p>
            <p>{{ item.contenttwo }}</p>
            <p>{{ item.contentthree }}</p>
            <p>{{ item.contentfour }}</p>
            <p>{{ item.contentfive }}</p>
            <p>{{ item.contentsix }}</p>
          </div>
          <div class="card-price-month" style="margin-top: 60px">
            <!-- ¥400/月 -->
            {{ item.price == 0 ? "免费" : item.price }}
          </div>
          <div class="card-price-month" style="margin-bottom: 36px">
            <!-- 月付 ¥480/月 -->
            {{ item.price2 == 0 ? "&nbsp;" : item.price2 }}
          </div>
          <!-- <el-button
            :class="comboId > item.comboId ? 'nobuybtn' : 'buybtn'"
            :disabled="comboId > item.comboId"
            @click="toPayPage(item)"
          >
            {{ comboId > item.comboId ? "你已购买更高版本" : item.butLabel }}
          </el-button> -->
        </div>
      </div>
    </span>

    <!-- 卡片三 -->
    <span slot="customTitleThree">
      <div
        v-for="(item, index) in list.slice(2, 3)"
        :key="index"
        class="other"
        :style="item.color"
        :class="index == 3 ? 'cla' : ''"
      >
        <div class="other-col" :class="index == 3 ? 'ccca' : ''">
          <div class="card-title">
            {{ item.varName }}
          </div>
          <div class="card-title">
            {{ item.enName }}
          </div>
          <div
            v-show="showText"
            style="
              margin-top: 64px;
              height: 100px;
              text-align: center;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              font-size: 15px;
            "
          >
            <p>{{ item.contentone }}</p>
            <p>{{ item.contenttwo }}</p>
            <p>{{ item.contentthree }}</p>
            <p>{{ item.contentfour }}</p>
            <p>{{ item.contentfive }}</p>
            <p>{{ item.contentsix }}</p>
          </div>
          <div class="card-price-month" style="margin-top: 60px">
            <!-- ¥400/月 -->
            {{ item.price == 0 ? "免费" : item.price }}
          </div>
          <div class="card-price-month" style="margin-bottom: 36px">
            <!-- 月付 ¥480/月 -->
            {{ item.price2 == 0 ? "&nbsp;" : item.price2 }}
          </div>
          <!-- <el-button
            :class="comboId > item.comboId ? 'nobuybtn' : 'buybtn'"
            :disabled="comboId > item.comboId"
            @click="toPayPage(item)"
          >
            {{ comboId > item.comboId ? "你已购买更高版本" : item.butLabel }}
          </el-button> -->
        </div>
      </div>
    </span>

    <!-- 卡片四 -->
    <span slot="customTitleFour">
      <div
        v-for="(item, index) in list.slice(3, 4)"
        :key="index"
        class="other"
        :style="item.color"
        :class="index == 3 ? 'cla' : ''"
      >
        <div class="other-col" :class="index == 3 ? 'ccca' : ''">
          <div class="card-title">
            {{ item.varName }}
          </div>
          <div class="card-title">
            {{ item.enName }}
          </div>
          <div
            v-show="showText"
            style="
              margin-top: 64px;
              height: 100px;
              text-align: center;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              font-size: 15px;
            "
          >
            <p>{{ item.contentone }}</p>
            <p>{{ item.contenttwo }}</p>
            <p>{{ item.contentthree }}</p>
            <p>{{ item.contentfour }}</p>
            <p>{{ item.contentfive }}</p>
            <p>{{ item.contentsix }}</p>
          </div>
          <div class="card-price-month" style="margin-top: 60px">
            <!-- ¥400/月 -->
            {{ item.price == 0 ? "免费" : item.price }}
          </div>
          <div class="card-price-month" style="margin-bottom: 36px">
            <!-- 月付 ¥480/月 -->
            {{ item.price2 == 0 ? "&nbsp;" : item.price2 }}
          </div>
          <!-- <el-button
            :class="comboId > item.comboId ? 'nobuybtn' : 'buybtn'"
            :disabled="comboId > item.comboId"
            @click="toPayPage(item)"
          >
            {{ comboId > item.comboId ? "你已购买更高版本" : item.butLabel }}
          </el-button> -->
        </div>
      </div>
    </span>
  </a-table>
</template>

<script>
import { columns } from '@/views/shop/StorePackage/StorePackageData'
export default {
  props: {
    list: {
      type: Array,
      default: () => [
        {
          color: 'background-color: #EE9CA7;',
          comboId: 1,
          varName: '爱心版',
          enName: 'Love version',
          contentone: 'Vllshop响应国家号召',
          contenttwo: '针对应届大学生、',
          contentthree: '退伍军人、残疾人',
          contentfour: '等特殊群体的',
          contentfive: '就业和创业',
          contentsix: '提供爱心版免费服务',
          price: '0',
          price2: '0',
          butLabel: '申请套餐',
          type: 'V1'
        },
        {
          color: 'background-color: #4B6CB7;',
          comboId: 2,
          varName: '基础版',
          enName: 'Basic Edition',
          contentone: '绝佳的启动方式',
          contenttwo: '适合新手卖家快速上手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '4300元/年',
          price2: '430元/月',
          butLabel: '购买套餐',
          type: 'V2'
        },
        {
          color: 'background-color: #19397B;',
          comboId: 3,
          varName: '专业版',
          enName: 'Professional Edition',
          contentone: '轻松运营销量节节攀升',
          contenttwo: '晋级跨境电商职业选手',
          contentthree: '',
          contentfour: '',
          contentfive: '',
          contentsix: '',
          price: '8800元/年',
          price2: '880元/月',
          butLabel: '购买套餐',
          type: 'V3'
        },
        {
          color: 'background-color: #121A43;',
          comboId: 4,
          varName: '旗舰定制版',
          enName: 'Fagship',
          contentone: 'Vllshop给买家提供旗舰定',
          contenttwo: '制版独立站服务',
          contentthree: '提供零到一一站式服务',
          contentfour: '独立站销售培训',
          contentfive: '运营经验分享',
          contentsix: '专属咨询顾问一对一服务',
          price: '89999年/元',
          price2: '0',
          butLabel: '购买套餐',
          type: 'V4'
        }
      ]
    }
  },
  data() {
    return {
      columns,
      showText: true,
      comboId: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.cla {
  flex: 1 !important;
  min-width: 215px !important;
}

// 价格
.card-price-month {
  //   margin-bottom: 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

// 功能细则，第一个卡片
.card-one {
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: inherit;
  background: #fff;
}
.content-txt {
  color: #191a1c;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
}

// 套餐介绍卡片样式
.card-title {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
.other {
  // min-width: 200px;
  background: #fff;
  color: #fff;
  height: inherit;
  display: flex;
  align-items: center;
  &-col {
    // min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// 用一个表格当头部
.table-head {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5000;
  top: 91px;
}

.table-head ::v-deep .ant-table-placeholder {
  display: none;
}

::v-deep .ant-table-row-cell-break-word {
  background-color: #fff;
}

// 处理第三个专业版文字过长导致的高度变化
::v-deep .customTitleClass.ant-table-align-center:nth-last-child(2) {
  padding: unset;
}

// 处理各个等级颜色(基础)
::v-deep .customTitleClass.ant-table-align-center:nth-last-child(5) {
  background-color: #fff;
  padding: 0px;
}

::v-deep .customTitleClass.ant-table-align-center:nth-last-child(4) {
  background-color: #ee9ca7;
}

::v-deep .customTitleClass.ant-table-align-center:nth-last-child(3) {
  background-color: rgb(75, 108, 183);
}

::v-deep .customTitleClass.ant-table-align-center:nth-last-child(2) {
  background-color: rgb(25, 57, 123);
}

::v-deep .customTitleClass.ant-table-align-center:nth-last-child(1) {
  background-color: rgb(18, 26, 67);
}
</style>
