<template>
  <div class="testimonial-section">
    <div class="testimonial-section-wrapper">
      <b-container>
        <b-row>
          <b-col lg="12" offset-lg="0" md="8" offset-md="2" cols="10" offset="1">
            <div class="section-heading center width-55">
              <div class="sub-heading c-blue upper ls-1">
                <i class="las la-comments" />
                <h5></h5>
              </div>
              <div class="main-heading c-dark">
                <h1 style="font-size: 3.6rem"> 来看看他们怎么说</h1>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row>
          <div class="testimonial-slider">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <!--    Swiper-Item       -->
                <div class="swiper-slide" v-for="(item,index) in testimonialData" :key="index">
                  <div class="testimonial-slide">
                    <div class="image">
                      <div class="image-wrapper">
                        <div class="image-inner">
                          <img :src="item.qAvatar" alt="testimony-image">
                        </div>
                      </div>
                    </div>
                    <div class="content">
                      <h5>客户咨询</h5>
                      <p>{{item.q}}</p>
                      <h5>我们的建议</h5>
<!--                      <h5>{{item.qName}}</h5>-->
                      <p>{{item.a}}</p>
<!--                      <h5>- 我们的建议</h5>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Swiper from '@/docs/swiper-bundle.min'

export default {
  name: 'TestimonialSection',
  props: {
    testimonialData: {
      type: Array,
      default: () => {
        return [{
          q: '我们公司是做流行饰品的，销售地区主要在英国和美国，之前都是通过参加国外的时尚展会和T台走秀以及业务员开发客户的模式进行销售的。但是这段时间因为各种原因不能到国外进行线下的客户拓展，同时越来越多的个体和新客户需要线上看产品，所以我们打算搭建一个独立站。',
          a: '根据您的详细描述我了解到贵公司的情况是这样的：线下的主要销售地区使用的语言场景是以英文为主，搭建网站的主要目的是方便客户在线查看产品，二是通过网站获取询盘，以弥补不能参加线下的产品展示活动所减少的潜在客户。那么刚开始我建议选择基础版就能满足建站需求了。',
          qName: 'Alex',
          qAvatar: require('../../assets/img/avatar/Alex.png'),
          aAvatar: require('../../assets/img/avatar/1.png')
        }, {
          q: '我们公司是做服装的，已经在美国做了7年了。主要做美国，加拿大等地区，但是现在市场竞争十分激烈，所以想尝试拓展更多的地区。申请注册的欧洲站，做铺货，也是自发货。刚开始没人带，都是自己在琢磨，每天自己手动上货，一天两件，最多最快的时候也是上四件产品，做了一个多月出单量都是为零。开始怀疑人生，加上语音的不同，所以我们打算搭建一个独立站。',
          a: '根据您的详细描述我了解到贵公司的情况是这样的：独立站的确能通过谷歌搜索引擎或者Facebook等社交媒体展示到不同的地区。这时，也解决了您的网站就需要有不同的语种，因为谷歌搜索引擎会针对不同的地区，搜索不同语种的网站。如果你想通过独立站去了解市场并尝试开拓，我建议选择我们的高级版，可以自动翻译多语言，还解决了零售、批发、球CDN加速、PP&信用卡收款、和优惠券等电商运营手段增加客户粘性，更好的帮助您的企业深度挖掘行业客户。',
          qName: 'Christina',
          qAvatar: require('../../assets/img/avatar/Christina.png'),
          aAvatar: require('../../assets/img/avatar/2.png')
        }, {
          q: '我们公司本来就是做国内电商的，主要是买一些轻奢类的家居，因为现在公司要扩大规模，所以我们想做一个全球性的独立站，因为我们原来就是有一些经验，所以想了解一下我们平台，发展国外业务。',
          a: '您好，根据您的描述我大概有一些了解，因为对于平台来说，独立站是比较适合通过谷歌等社交媒体进行推广的，如果想做全球的业务那就需要您选择有不同语种的VllShop，因为谷歌根据不同的搜索引擎针对不同的地区，不同语种的网站进行推广，也可以让之前的一些老客户进行复购，引导老用户到你们独立站进行下单购买，查看新产品，让您的独立站人流量丰富起来，加固用户粘性，同时也可以降低成本，更好的给公司带来收益。',
          qName: 'Adam',
          qAvatar: require('../../assets/img/avatar/Adam.png'),
          aAvatar: require('../../assets/img/avatar/3.png')
        }]
      }
    }
  },
  data() {
    return {
      testimonial_slider: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      const testimonial_slider = new Swiper('.testimonial-slider .swiper-container', {
        resizeObserver: true,
        spaceBetween: 0,
        initialSlide: 1,
        init: false,
        speed: 250,
        slideToClickedSlide: true,
        breakpoints: {
          0: {
            direction: 'horizontal',
            centeredSlides: true,
            loop: true,
            slidesPerView: 1.7
          },
          992: {
            direction: 'vertical',
            centeredSlides: true,
            loop: true,
            slidesPerView: 2.0
          }
        }
      })
      testimonial_slider.init()
    })
  }
}
</script>

<style lang="scss" scoped>
.section-heading.width-55 {
  width: 55%;
}
.section-heading.center {
  text-align: center;
  margin: 0 auto;
}
.section-heading {
  position: relative;
  z-index: 2;
}
.section-heading.width-55
.testimonial-slide {
  display: flex;
  align-items: center;
  width: 100%
}
.section-heading.center .sub-heading {
  justify-content: center;
}
.section-heading .sub-heading {
  display: flex;
  align-items: center;
  margin: 0 auto 1.8rem 0;
}
.ls-1 {
  letter-spacing: .1rem;
}
.upper {
  text-transform: uppercase;
}
.c-blue {
  color: #5178e8;
  fill: #5178e8;
}
.section-heading .sub-heading i {
  font-size: 2.4rem;
  margin: 0 .8rem 0 0;
}
.section-heading .sub-heading h5 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: .3rem 0 0 0;
}
.c-dark {
  color: #123769;
  fill: #123769;
}
h1 {
  font-size: 6rem;
  font-weight: 600;
  line-height: 8rem;
  letter-spacing: -.2rem;
}
@media (max-width: 991px) {
  .testimonial-slide {
    flex-direction: column
  }
}
.testimonial-slide .image {
  background-color: transparent;
  border-radius: 50%;
  padding: .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .25s ease-out 0s
}
@media (min-width: 992px) {
  .testimonial-slide .image {
    margin-right: 8rem
  }
}
.testimonial-slide .image-wrapper {
  background-color: #fff;
  border-radius: 50%;
  padding: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .25s ease-out 0s
}
@media (max-width: 991px) {
  .testimonial-slide .image-wrapper {
    font-size: 1.6rem
  }
}
.testimonial-slide .image-inner {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: center center;
  transform: scale(0.61);
  transition: all .25s ease-out 0s;
  position: relative;
  overflow: hidden
}
.testimonial-slide .image-inner img {
  width: 100%;
  height: 100%;
  border: .1rem solid #fff;
  position: relative;
  z-index: 1;
  border-radius: 50%
}
.testimonial-slide .image-inner::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 50%;
  transition: all .25s ease-out 0s;
  opacity: 1;
  visibility: visible
}
.testimonial-slide .content {
  width: 55%;
  opacity: 0;
  visibility: hidden;
  transition: all .25s ease-out 0s
}
.testimonial-slide .content p {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 4rem;
  color: #6a6f77
}
.testimonial-slide .content h5 {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 4rem;
  color: #123769;
  margin-top: 3rem
}
@media (max-width: 991px) {
  .testimonial-slide .content {
    width: 150%;
    margin-top: 5rem;
    text-align: center
  }
}
@media (max-width: 991px) {
  .testimonial-slider {
    margin-top: 1rem
  }
}

@media (min-width: 992px) {
  .testimonial-slider .swiper-container {
    height: 69rem
  }
}
.testimonial-slider .swiper-slide {
  display: flex
}
.testimonial-slide {
  display: flex;
  align-items: center;
  width: 100%;
}

.testimonial-slider .swiper-slide-active .image {
  z-index: 10;
  padding: .4rem;
  background: rgb(153, 157, 253);
  background: linear-gradient(45deg, rgba(128, 226, 255, 1) 20%, rgb(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%)
}
.testimonial-slider .swiper-slide-active .image-inner {
  transform: scale(1)
}

.testimonial-slider .swiper-slide-active .image-inner::after {
  opacity: 0;
  visibility: hidden
}
.testimonial-slider .swiper-slide-active .content {
  opacity: 1;
  visibility: visible
}
.testimonial-slider .swiper-slide-prev .image {
  z-index: 1;
  margin-top: 17.5%;
  cursor: pointer
}
.testimonial-slider .swiper-slide-prev .image-inner::after {
  background: #fff;
  background: linear-gradient(180deg, #fff 20%, rgba(0, 0, 0, 0) 100%)
}
@media (max-width: 991px) {
  .testimonial-slider .swiper-slide-prev .image {
    margin: 0 -50.5% 0 0
  }

  .testimonial-slider .swiper-slide-prev .image-inner::after {
    background: #fff;
    background: -webkit-gradient(linear, left top, right top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(90deg, #fff 20%, rgba(0, 0, 0, 0) 100%)
  }
}

.testimonial-slider .swiper-slide-next .image {
  z-index: 1;
  margin-top: -17.5%;
  cursor: pointer
}

.testimonial-slider .swiper-slide-next .image-inner::after {
  background: #fff;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, #fff 20%, rgba(0, 0, 0, 0) 100%)
}
@media (max-width: 991px) {
  .testimonial-slider .swiper-slide-next .image {
    margin: 0 0 0 -50.5%
  }

  .testimonial-slider .swiper-slide-next .image-inner::after {
    background: #fff;
    background: -webkit-gradient(linear, right top, left top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(-90deg, #fff 20%, rgba(0, 0, 0, 0) 100%)
  }
}

.testimonial-section-wrapper {
  padding: 7rem 0 14rem 0;
}

@media (max-width: 991px) {
  .testimonial-section-wrapper {
    padding: 6rem 0 5rem 0
  }
  h1 {
    font-size: 5rem;
    line-height: 7rem;
  }
  .testimonial-slider{
    .swiper-wrapper{
      width: 85vw;
    }
  }
}
@media (max-width: 576px) {
  .testimonial-slider{
    width: 100%;
  }
}

</style>
